import React from "react";
import { Box, Label } from "theme-ui";
import { InfoIcon } from "./Icon";
import Tooltip from "./Tooltip";

const SVG = ({ size = 24, ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size + ""}
    height={size + ""}
    viewBox="0 0 24 24"
    fill="currentcolor"
    {...props}
  />
);

const CheckboxChecked = (props) => (
  <SVG {...props}>
    <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
  </SVG>
);

const CheckboxUnchecked = (props) => (
  <SVG {...props}>
    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
  </SVG>
);

const CheckboxIcon = (props) => (
  <React.Fragment>
    <CheckboxChecked
      {...props}
      __css={{
        display: "none",
        "input:checked ~ &": {
          display: "block",
        },
      }}
    />
    <CheckboxUnchecked
      {...props}
      __css={{
        display: "block",
        "input:checked ~ &": {
          display: "none",
        },
      }}
    />
  </React.Fragment>
);

export const CheckboxIconBox = React.forwardRef(
  (
    { className, sx, variant = "checkbox", label, icon, tooltip, ...props },
    ref
  ) => (
    <Label
      tabIndex="0"
      for={props.id}
      sx={{
        cursor: "pointer",
        bg: "muted",
        border: "1px solid grey",
        pb: "1rem",

        overflow: "none",
        display: "block",
        textAlign: "center",

        ":focus-visible": {
          border: "2px solid red",
        },
      }}
    >
      <Box
        tabIndex="-1"
        ref={ref}
        as="input"
        type="checkbox"
        {...props}
        sx={{
          position: "absolute",
          opacity: 0,
          zIndex: -1,
          width: 1,
          height: 1,
          overflow: "hidden",
        }}
      />
      <Box
        tabIndex="-1"
        as={CheckboxIcon}
        aria-hidden="true"
        __themeKey="forms"
        variant={variant}
        className={className}
        sx={sx}
        __css={{
          ml: "auto",
          mr: 2,
          mt: "2px",
          borderRadius: 4,
          "input:checked ~ &": {
            color: "primary",
          },
          "input:focus ~ &": {
            color: "primary",
          },
        }}
      />
      <Box>{icon}</Box>
    
      {label}
      {tooltip && (
        <Tooltip
          sx={{
            display: "inline",
          }}
          placement="bottom"
          tooltip={tooltip}
          customStyle={{
            marginTop: 3,
            hideTooltipButtonClose: "false",
            display: "inline",
          }}
        >
          <InfoIcon
            customStyle={{ display: "inline" }}
            sx={{
              display: "inline",
              color: "primary",
              marginLeft: "0.3em",
              verticalAlign: "middle",
            }}
          />
        </Tooltip>
      )}

    </Label>
  )
);
