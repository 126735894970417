/** @jsx jsx */
import { jsx, Box, Flex, Grid, Label, Themed } from "theme-ui";
import colors from "../gatsby-plugin-theme-ui/colors";
import { InfoIcon } from "./Icon";
import Tooltip from "./Tooltip";

const CheckboxFieldGroup = ({
  children,
  hidden,
  visible,
  tooltip,
  ...props
}) => {
  const { error } = colors;
  const labelformat = props.labelformat ? "labels." + props.labelformat : "";

  if (props.layout && props.layout === "iconBox") {
    return hidden || visible === false ? null : (
      <Box
      as="fieldset" sx={{ width: "100%", padding: 0, border: 'none' }}>
        {props.label && (
          <Label as="legend" htmlFor={props.name} variant={labelformat}>
            {props.label}
            {tooltip && (
              <Box sx={{ ml: 2 }}>
                <Tooltip placement="auto" tooltip={tooltip}>
                  <InfoIcon sx={{ color: "primary" }} />
                </Tooltip>
              </Box>
            )}
          </Label>
        )}
        <Grid
          sx={{
            gridTemplateColumns: ["repeat(auto-fill, minmax(45%, 1fr))", "repeat(auto-fill, minmax(30%, 1fr))", "repeat(auto-fill, minmax(20%, 1fr))"],
            gridAutoRows: "1fr",
            mb: 1,
          }}
        >
          {children}
        </Grid>
        {props.error && (
          <div role="alert" sx={{ color: "error", fontSize: 0 }}>
            {props.error}
          </div>
        )}
      </Box>
    );
  } else {
    return hidden || visible === false ? null : (
      <Box as="fieldset" sx={{ mb: 3, width: props.width ? props.width : "100%", padding: 0, border: 'none' }}>
        {props.label && (
          <Label as="legend" htmlFor={props.name} variant={labelformat}>
            {props.label}
            {tooltip && (
              <Box sx={{ ml: 2 }}>
                <Tooltip placement="auto" tooltip={tooltip}>
                  <InfoIcon sx={{ color: "primary" }} />
                </Tooltip>
              </Box>
            )}
          </Label>
        )}
        {children}
        {props.error && (
          <div role="alert" sx={{ color: "error", fontSize: 0 }}>
            {props.error}
          </div>
        )}
      </Box>
    );
  }
};

export default CheckboxFieldGroup;
