/** @jsx jsx */
import { jsx, Box, Label, Flex, Grid } from "theme-ui";
import { Checkbox } from "./Checkbox";
import { CheckboxIconBox } from "./CheckboxIconBox";
import colors from "../gatsby-plugin-theme-ui/colors";
import { InfoIcon } from "./Icon";
import Tooltip from "./Tooltip";

const CheckboxField = ({
  field,
  id,
  label,
  setFieldValue,
  layout,
  icon,
  tooltip,
  ...props
}) => {
  const { error } = colors;

  if (layout && layout.split("_")[0] === "iconBox") {
    return (
      <CheckboxIconBox
        icon={icon}
        label={label}
        name={field.name}
        tooltip={tooltip}
        id={id}
        value={!props.multiple ? field.value : id}
        checked={
          !props.multiple
            ? field.value
            : field.value && field.value.includes(id)
        }
        onChange={
          !props.multiple
            ? field.onChange
            : (evt) => {
                if (evt.target.checked) {
                  if (!field.value.includes(evt.target.value)) {
                    setFieldValue(field.name, [
                      ...field.value,
                      evt.target.value,
                    ]);
                  }
                } else {
                  setFieldValue(
                    field.name,
                    field.value.filter((v) => v !== evt.target.value)
                  );
                }
              }
        }
        onBlur={field.onBlur}
        {...props}
      />
    );
  } else {
    return (
      <Box sx={{ width: props.width ? props.width : "auto", mr: 2, mb: 1 }}>
        <Label
         for={id}
          variant={
            props.labelformat ? "labels." + props.labelformat : "text.body"
          }
          sx={{
            maxWidth: "max-content",
          }}
        >
          <Checkbox
            name={field.name}
            id={id}
            value={!props.multiple ? field.value : id}
            checked={
              !props.multiple
                ? field.value
                : field.value && field.value.includes(id)
            }
            onChange={
              !props.multiple
                ? field.onChange
                : (evt) => {
                    if (evt.target.checked) {
                      setFieldValue(
                        field.name,
                        field.value !== undefined
                          ? [...field.value, evt.target.value]
                          : [evt.target.value]
                      );
                    } else {
                      setFieldValue(
                        field.name,
                        field.value !== undefined
                          ? field.value.filter((v) => v !== evt.target.value)
                          : []
                      );
                    }
                  }
            }
            onBlur={field.onBlur}
            {...props}
          />
          <span dangerouslySetInnerHTML={{ __html: label }}></span>
          {tooltip && (
            <Box sx={{ ml: 2 }}>
              <Tooltip placement="auto" tooltip={tooltip}>
                <InfoIcon sx={{ color: "primary" }} />
              </Tooltip>
            </Box>
          )}
        </Label>
        {props.error && (
          <div role="alert" style={{ color: error, fontSize: "0.875rem" }}>
            {props.error}
          </div>
        )}
      </Box>
    );
  }
};

export default CheckboxField;
