/** @jsx jsx */
import { jsx, Box, Button, Label } from "theme-ui";
import React, { useRef } from "react";
import { isBrowser } from "../utils/utils.js";
import ReCAPTCHA from "react-google-recaptcha";
import { sendCaptchaEvent } from "../utils/analytics";

const RecaptchaButton = ({
  handleSubmit,
  isSubmitting,
  setFieldValue,
  field = "recaptcha",
  buttonText,
  language = "fi",
  errors,
}) => {
  const recaptchaInstance = useRef(null);

  const validate = () =>
    (!errors && !Object.keys(errors).length) ||
    (Object.keys(errors).length === 1 && errors.recaptcha);

  const executeCaptcha = () => {
    const isValid = validate();
    if (isValid)
      recaptchaInstance &&
        recaptchaInstance.current &&
        recaptchaInstance.current.execute();
  };
  const resetRecaptcha = () => {
    recaptchaInstance &&
      recaptchaInstance.current &&
      recaptchaInstance.current.reset();
  };
  const submit = (response) => {
    setFieldValue(field, response);
    if (!response) {
      sendCaptchaEvent(false);
    } else {
      sendCaptchaEvent(true);
    }
    setTimeout(() => {
      reset();
    }, 100);
    handleSubmit && handleSubmit();
  };
  const reset = () => {
    resetRecaptcha();
    setFieldValue(field, "");
  };
  const error = () => {
    sendCaptchaEvent(false);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Button
        id="recaptcha"
        type="submit"
        onClick={executeCaptcha}
        disabled={isSubmitting}
      >
        {buttonText}
      </Button>
      {isBrowser && (
        <ReCAPTCHA
          ref={recaptchaInstance}
          size="invisible"
          badge="bottomleft"
          sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
          onChange={submit}
          onExpired={reset}
          onErrored={error}
          hl={language}
        />
      )}
      <Label htmlFor="g-recaptcha-response" style={{ display: "none" }}>
        Recaptcha
      </Label>
    </Box>
  );
};

export default RecaptchaButton;
