/** @jsx jsx */
import { jsx, Flex, Button } from "theme-ui";
import { usePopperTooltip } from "react-popper-tooltip";
import React, { useEffect, useRef, useContext, useState } from "react";
import { LocalizationContext } from "../context/LocalizationContext";
import { removeHtmlTags } from "../utils/utils";

const Tooltip = ({ children, tooltip, hideArrow, customStyle, ...props }) => {
  const [controlledVisible, setControlledVisible] = useState(false);
  const ref = useRef();
  const { translate } = useContext(LocalizationContext);

  const {
    setTriggerRef,
    getTooltipProps,
    setTooltipRef,
    getArrowProps,
    tooltipRef,
    visible,
    arrowRef,
    placement,
    ...popperProps
  } = usePopperTooltip(
    {
      trigger: ["click", "hover", "focus"],
      visible: controlledVisible,
      onVisibleChange: setControlledVisible,
      ...props,
    },
    { placement: props.placement }
  );

  const onKeyDownHandler = (e) => {
    if (e.defaultPrevented) {
      return;
    }

    if (e.key === "Escape" || e.keyCode === 27) {
      setControlledVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDownHandler);

    return () => {
      document.removeEventListener("keydown", onKeyDownHandler);
    };
  });

  return (
    <>
      {controlledVisible && (
        <Flex
          {...getTooltipProps({
            ref: tooltipRef,
          })}
          ref={setTooltipRef}
          sx={{
            bg: "secondary",
            color: "white",
            borderRadius: 1,
            border: "none",
            boxShadow: "dropdown",
            p: 3,
            mt: 2,
            transition: "opacity .25s",
            zIndex: 389383,
            fontFamily: "body",
            fontSize: 0,
            fontWeight: "normal",
            lineHeight: "1.5",
            maxWidth: ["100%", "480px"],
            '&[data-popper-placement*="right"]': {
              marginLeft: 1,
            },
            '&[data-popper-placement*="left"]': {
              marginRight: 1,
            },
            '&[data-popper-placement*="top"]': {
              marginBottom: 1,
            },
            '&[data-popper-placement*="bottom"]': {
              marginTop: customStyle?.marginTop ? customStyle.marginTop : 1,
            },
          }}
        >
          {!hideArrow && (
            <div
              {...getArrowProps({
                ref: arrowRef,
                "data-placement":
                  popperProps.state && popperProps.state.placement,
              })}
              sx={{
                content: '""',
                display: "block",
                position: "absolute",
                width: 0,
                height: 0,
                margin: "auto",
                borderStyle: "solid",
                borderWidth: 6,
                borderColor: "transparent",
                '&[data-placement*="right"]': {
                  left: -12,
                  borderRightColor: "secondary",
                },
                '&[data-placement*="left"]': {
                  right: -12,
                  borderLeftColor: "secondary",
                },
                '&[data-placement*="top"]': {
                  bottom: -12,
                  borderTopColor: "secondary",
                },
                '&[data-placement*="bottom"]': {
                  top: -12,
                  borderBottomColor: "secondary",
                },
              }}
            />
          )}
          <div
            sx={{
              textAlign: "left",
            }}
            dangerouslySetInnerHTML={{ __html: tooltip }}
          />
          {!customStyle?.hideTooltipButtonClose && (
            <Button
              variant="plain"
              aria-label={translate("tooltip.close")}
              onClick={() => setControlledVisible(false)}
              sx={{
                position: "absolute",
                top: 0,
                right: "4px",
                p: 1,
                lineHeight: 1,
                color: "currentColor",
                ":hover": {
                  color: "currentColor",
                  opacity: 0.9,
                },
              }}
            >
              <span aria-hidden="true">&times;</span>
            </Button>
          )}
        </Flex>
      )}

      <div
        sx={{
          display: customStyle?.display ? customStyle.display : "block"
        }}
        ref={setTriggerRef}
        tabIndex={0}
        aria-label={removeHtmlTags(tooltip)}
        role="tooltip"
      >
        {children}
      </div>
    </>
  );
};

export default Tooltip;
