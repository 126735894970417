/** @jsx jsx */
import {
  Formik,
  Field,
  FieldArray,
  Form as FormikForm,
  withFormik,
  ErrorMessage,
  useFormikContext,
} from "formik";
import { navigate } from "gatsby";
import { evaluate, forEach } from "mathjs";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Textarea from "react-textarea-autosize";
import { Box, Button, Flex, jsx, Themed } from "theme-ui";
import * as yup from "yup";
import { LocalizationContext } from "../context/LocalizationContext";
import { NotificationContext, SEVERITY } from "../context/NotificationContext";
import { sendFormSubmitEvent } from "../utils/analytics";
import * as api from "../utils/api";
import carModelId from "../utils/CarModelId";
import { handleFormValidationError } from "../utils/utils";
import CheckboxField from "./CheckboxField";
import CheckboxFieldGroup from "./CheckboxFieldGroup";
import FormField from "./FormField";
import {
  InfoIcon,
  InsuranceHome,
  InsuranceLife,
  InsurancePet,
  InsuranceTravel,
  InsurancePerson,
  InsuranceCar,
  InsuranceBoat,
  InsuranceAcquisition,
  InsuranceCompanies,
  InsuranceClipboard,
  InsuranceCheckAll
} from "./Icon";
import Link from "./Link";
import RadioField from "./RadioField";
import RadioFieldGroup from "./RadioFieldGroup";
import RecaptchaButton from "./RecaptchaButton";
import Spinner from "./Spinner";
import Tooltip from "./Tooltip";
import { CONTENTFUL_CONSTANTS } from "../utils/constants";
import customParseFormat from "dayjs/plugin/customParseFormat";
import colors from "../gatsby-plugin-theme-ui/colors";

const { BASE_URL_OF, BASE_URL_OF_SV } = CONTENTFUL_CONSTANTS;

dayjs.extend(customParseFormat);

const phoneRegExp = /^\+?[0-9 \-#()]{6,18}$/;
const yearRegExp = /^\d{4}$/;
const decimalRegExp = /^\d{1,4}([.,]\d)?$/;
const ssnRegExp = /^\d{6}[A+-]\d{3}[a-zA-Z0-9]$/;
const vatRegExp = /^\d{7}-\d{1}$/;
const handlingNbrRegExp = /^\d{7}([a-zA-Z]{1})$/;
const postNumberRegExp = /^\d{5}$/;
const dateRegExp = /^\d{1,2}.\d{1,2}.\d{4}$/;
const numbersOnly = /^[0-9]*$/;
const danskeBankBId = /^[A-Za-z]{0,2}[0-9]*$/;
const fenniaInvoiceNbr = /^[0-9]{9}$/;
const fenniaCustomerNbr = /^[0-9]{6,7}$/;

const replaceLinks = {
  "Oma Fennia": BASE_URL_OF,
  "Mitt Fennia": BASE_URL_OF_SV,
};

const getIcon = (iconName, fillColor) => {
  const icons = {
    InsuranceHome,
    InsuranceLife,
    InsurancePet,
    InsuranceTravel,
    InsurancePerson,
    InsuranceCar,
    InsuranceBoat,
    InsuranceAcquisition,
    InsuranceCompanies,
    InsuranceClipboard,
    InsuranceCheckAll
  };
  const DynamicIcon = icons[iconName];
  return DynamicIcon ? <DynamicIcon fill={fillColor} /> : undefined;
};

let isSecuredForm = false;
let hashedPswToken = false;

const infoContainsInternalName = (text) => {
  const internalNames = Object.keys(replaceLinks);
  if (internalNames.some((name) => text.includes(name))) {
    const nameIncluded = internalNames.find((name) => text.includes(name));
    return { contains: true, name: nameIncluded };
  } else return { contains: false };
};

const renderLinkDescription = (description) => {
  const { contains, name } = infoContainsInternalName(description);
  if (contains) {
    const link = replaceLinks[name];
    const newDescription = description.replace(name, function (s) {
      return `<a target='_blank' href='${link}'>${name}</a>`;
    });
    return (
      <Themed.p
        sx={{ a: { color: "#008A28", textDecoration: "none" } }}
        dangerouslySetInnerHTML={{ __html: newDescription }}
      />
    );
  } else {
    return <Themed.p>{description}</Themed.p>;
  }
};

// scroll to first fieldError
const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };
  return transformObjectToDotNotation(formikErrors);
};

const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView(scrollBehavior);

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

const generateValidationSchema = (
  formFields,
  translate,
  language,
  disableCaptcha = false
) => {
  let flattenedFields = [];
  formFields.forEach((item) => {
    if (!visibleFormFields.includes(item.id)) return;
    if (!item.children) {
      flattenedFields.push(item);
    } else {
      flattenedFields.push(item);

      const parseChilds = (itm, flattenedFields) => {
        itm.children.forEach((child) => {
          if (!visibleFormFields.includes(child.id)) return;
          child.parentId = itm.id;
          flattenedFields.push(child);
          if (child.children) parseChilds(child, flattenedFields);
        });
      };
      parseChilds(item, flattenedFields);
    }
  });
  const yupSchema = flattenedFields.reduce((schema, field) => {
    const { id, type, required, label, values, parentId, showIf } = field;
    // skip informatory details
    if (type === "info") return schema;
    let validator = null;
    if (type === "checkbox") {
      validator = yup.bool(translate("form.invalidField", { label }, language));
    } else if (type === "checkboxgroup") {
      if (required) {
      validator = yup
        .array()
        .min(1, translate("form.requiredField", { label }, language));
      }
    } else {
      validator = yup.string(
        translate("form.invalidField", { label }, language)
      );
    }
    if (type === "email") {
      validator = validator.email(
        translate("form.invalidEmailField", { label }, language)
      );
    } else if (type === "phone") {
      validator = validator.matches(
        phoneRegExp,
        translate("form.invalidPhoneField", { label }, language)
      );
    } else if (type === "date") {
      validator = validator
        .matches(
          dateRegExp,
          translate("form.invalidDateField", { label }, language)
        )
        .test(
          "date-valid",
          translate("form.invalidDateField", { label }, language),
          (value) =>
            value ? dayjs(value, "DD.MM.YYYY", "fi", true).isValid() : true
        );
    } else if (type === "year") {
      validator = validator.matches(
        yearRegExp,
        translate("form.invalidYearField", { label }, language)
      );
    } else if (type === "decimal") {
      validator = validator.matches(
        decimalRegExp,
        translate("form.invalidDecimalField", { label }, language)
      );
    } else if (type === "ssn") {
      validator = validator.matches(
        ssnRegExp,
        translate("form.invalidSSNField", { label }, language)
      );
    } else if (type === "handlingNbr") {
      validator = validator.matches(
        handlingNbrRegExp,
        translate("form.invalidHandlingNbrField", { label }, language)
      );
    } else if (type === "vat") {
      validator = validator.matches(
        vatRegExp,
        translate("form.invalidVATField", { label }, language)
      );
    } else if (type === "postNumber") {
      validator = validator.matches(
        postNumberRegExp,
        translate("form.invalidPostNumberField", { label }, language)
      );
    } else if (type === "number") {
      validator = validator.matches(
        numbersOnly,
        translate("form.invalidNumbersOnly", { label }, language)
      );
    } else if (type === "danskeBankBId") {
      validator = validator.matches(
        danskeBankBId,
        translate("form.invalidDanskeBankBId", { label }, language)
      );
    } else if (type === "fenniaInvoiceNbr") {
      validator = validator.matches(
        fenniaInvoiceNbr,
        translate("form.invalidFenniaInvoiceNbr", { label }, language)
      );
    } else if (type === "fenniaCustomerNbr") {
      validator = validator.matches(
        fenniaCustomerNbr,
        translate("form.invalidFenniaCustomerNbr", { label }, language)
      );
    } else if (type === "checkbox") {
      if (required) {
        // required checkbox = must be checked
        validator = validator.oneOf(
          [true],
          translate("form.requiredField", { label }, language)
        );
      }
    } else if (type === "radiogroup" || type === "select") {
      validator = validator.oneOf(
        values.map((val) => val.id),
        translate("form.requiredField", { label }, language)
      );
    } else if (type === "password") {
      validator = yup.string(
        translate("form.invalidField", { label }, language)
      );
    }

    // Required validation for fields not dependend upon parent fields value
    if (required) {
      validator = validator.required(
        translate("form.requiredField", { label }, language)
      );
    }
    schema[id] = validator;
    return schema;
  }, {});

  const recaptcha = disableCaptcha
    ? {}
    : { recaptcha: yup.string().required() };
  return yup.object().shape({
    ...yupSchema,
    ...recaptcha,
  });
};

const getWidth = (n) => {
  try {
    if (n.includes("%")) {
      return +n.substring(0, n.indexOf("%")) / 100;
    }
    let number = evaluate(n);
    if (number < 1) return number;
    else return 1;
  } catch (err) {
    console.log(n + " cannot be mathematically evaluated: " + err);
    return 1;
  }
};

const getType = (type) => {
  if (type === "email") return "email";
  if (type === "phone") return "tel";
  if (type === "password") return "password";
  // doesn't work correctly if (type === 'date') return 'date';
  return "text";
};

const SubmitButton = ({
  disableCaptcha,
  handleSubmit,
  isSubmitting,
  button,
  submit,
  setFieldValue,
  language,
  externalSubmit,
  carModelId,
  selectedFieldValues = {},
  errors,
  translate,
}) => {
  if (disableCaptcha) {
    return (
      <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
        {button}
      </Button>
    );
  } else if (externalSubmit) {
    // ModelId number for the selected car make is searched for and added to the URL
    const urlStr = externalSubmit.url;

    const carModel =
      carModelId.find(
        (element) => element.carMake === selectedFieldValues.mam
      ) || {};
    const cabUrl = `${urlStr}rno=${selectedFieldValues.rno}&mi=${carModel.modelId}&my=${selectedFieldValues.my}&mam=${selectedFieldValues.mam}`;
    const hasTarget =
      selectedFieldValues.rno &&
      selectedFieldValues.my &&
      selectedFieldValues.mam &&
      carModel.modelId;
    return (
      <Link
        target={hasTarget ? cabUrl : "#"}
        variant={hasTarget ? "buttons.primary" : "buttons.secondary"}
      >
        {button}
      </Link>
    );
  }
  return (
    <>
      {errors &&
        Object.keys(errors).length > 1 && ( // captcha not yet validated, therefor 1 or less
          <div
            role="alert"
            style={{ color: colors.error, fontSize: "0.875rem" }}
          >
            {translate("form.verifyAllRequiredFields", null, language)}
          </div>
        )}
      <RecaptchaButton
        buttonText={button}
        handleSubmit={submit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        language={language}
        errors={errors}
      />
    </>
  );
};

let visibleFormFields = [];

const FormComponent = ({
  formFields,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  button,
  language,
  values,
  errors,
  translate,
  isValid,
  isValidating,
  showNotification,
  type,
  externalSubmit,
  carModelId,
}) => {
  const submit = () => {
    // remove ckb_All values from checkboxgroups
    for (let k in values) {
      if (Array.isArray(values[k]) && values[k] != []) {
        values[k] = values[k].filter((el) => el.substr(0, 7) !== "ckb_All");
      }
    }
    if (!isValidating && !isValid) {
      showNotification(
        translate("form.notValid", null, language),
        SEVERITY.ERROR
      );
    }
    handleSubmit();
  };

  const disableCaptcha =
    type === "accessibilityFeedback" || type === "accessibleMaterialOrder";

  // Generate one form component, values and parentId -parameters are used for conditional rendering of child questions (recursion)
  // Values = current form values, parentId = id of child questions parent question
  const generateFormComponents = (field, values, parentId) => {
    if (
      isSecuredForm === true &&
      !hashedPswToken &&
      field.id !== "securedFormPsw"
    ) {
      return;
    }

    if (
      (field.id !== undefined && field.id === formFields[0].id) ||
      (field.id === undefined &&
        field.title !== undefined &&
        field.title === formFields[0].title &&
        field.type === "info")
    )
      visibleFormFields = [];

    let width = "100%";
    let columns = field.fieldColumns;
    if (Array.isArray(field.fieldColumns)) {
      columns.map(
        (field, i) => (columns[i] = getWidth(field).toFixed(2) * 100 + "%")
      );
      width = ["100%", columns];
    } else
      width = [
        "100%",
        columns ? getWidth(columns).toFixed(2) * 100 + "%" : "100%",
      ];

    if (
      field.showIf &&
      ((Array.isArray(values[parentId]) &&
        !field.showIf.find((elm) => values[parentId].includes(elm))) ||
        (!Array.isArray(values[parentId]) &&
          field.showIf &&
          !field.showIf.includes(
            values[parentId] === true ? parentId : values[parentId]
          )))
    )
      return;

    // if ckb_All in a checkboxgroup was clicked
    if (
      event &&
      event.target &&
      event.target.defaultValue &&
      field.type === "checkboxgroup"
    ) {
      let foundCkbAll = false;
      field.values.filter((elm) => {
        if (
          event.target.defaultValue === elm.id &&
          elm.id.substring(0, 7) === "ckb_All"
        )
          foundCkbAll = true;
      });

      if (foundCkbAll && event.target.checked) {
        // check all boxes in group
        let tmp = [];
        field.values.filter((elm) => {
          tmp.push(elm.id);
        });
        values[field.id] = tmp;
      } else if (foundCkbAll) {
        // remove all checks in a group
        values[field.id] = [];
      }
    }
    // if one checkbox in checkboxgroup was clicked where there is also a ckb_All checkbox
    else if (
      event &&
      event.target &&
      event.target.defaultValue &&
      event.target.defaultValue.substring(0, 4) === "ckb_" &&
      field.type === "checkboxgroup"
    ) {
      if (!event.target.checked) {
        // remove a check
        let tmp = [];
        values[field.id].filter((elm) => {
          if (elm !== event.target.defaultValue && !elm.includes("ckb_All"))
            tmp.push(elm);
        });
        values[field.id] = tmp;
      } else {
        // add check
        let formField = formFields.filter((elm) => elm.id === field.id);
        if (
          values[field.id].length === formField[0].values.length - 1 &&
          !formField[0].values.includes("ckb_All")
        )
          values[field.id].push("ckb_All");
      }
    }

    if (field.id) visibleFormFields.push(field.id);

    if (field.type === "info") {
      const { title, subtitle, description, tooltip, headerIcon } = field;
      return (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Flex
            sx={{
              alignItems: "center",
              mb: 1,
              mt: title ? [4, null, 6] : 0,
            }}
          >
            {headerIcon && (
              <Box sx={{ mr: "0.5rem" }}>
                {getIcon(headerIcon, colors.text)}
              </Box>
            )}
            {title && <Themed.h2 sx={{ m: 0 }}>{title}</Themed.h2>}
            {tooltip && (
              <Box sx={{ ml: 2 }}>
                <Tooltip placement="auto" tooltip={tooltip}>
                  <InfoIcon sx={{ color: "primary" }} />
                </Tooltip>
              </Box>
            )}
          </Flex>
          <Box>
            {subtitle && <Themed.h3>{subtitle}</Themed.h3>}
            {description && renderLinkDescription(description)}
          </Box>
        </Box>
      );
    } else if (
      [
        "text",
        "phone",
        "email",
        "date",
        "decimal",
        "year",
        "ssn",
        "handlingNbr",
        "vat",
        "postNumber",
        "password",
        "number",
        "danskeBankBId",
        "fenniaCustomerNbr",
        "fenniaInvoiceNbr",
      ].includes(field.type)
    ) {
      return (
        <FormField
          key={field.id}
          id={field.id}
          name={field.id}
          width={width}
          placeholder={field.placeholder}
          label={
            field.required && field.extraLabel
              ? field.label + " * " + field.extraLabel
              : field.required
              ? field.label + " *"
              : field.extraLabel
              ? field.label + " " + field.extraLabel
              : field.label
          }
          labelformat={field.labelformat}
          tooltip={field.tooltip}
          type={getType(field.type)}
          required={field.required}
          cssStyles={field.css}
        />
      );
    } else if (field.type === "textarea") {
      return (
        <FormField
          id={field.id}
          key={field.id}
          name={field.id}
          width={width}
          as={Textarea}
          placeholder={field.placeholder}
          label={
            field.required && field.extraLabel
              ? field.label + " * " + field.extraLabel
              : field.required
              ? field.label + " *"
              : field.extraLabel
              ? field.label + " " + field.extraLabel
              : field.label
          }
          labelformat={field.labelformat}
          type="text"
          tooltip={field.tooltip}
          required={field.required}
          minRows={5}
          sx={{ overflow: "hidden" }}
          cssStyles={field.css}
        />
      );
    } else if (field.type === "checkbox") {
      return (
        <React.Fragment>
          <Box
            sx={{
              mb: 3,
              width: width,
            }}
          >
            <Field
              component={CheckboxField}
              key={field.id}
              name={field.id}
              id={field.id}
              label={
                field.required && field.extraLabel
                  ? field.label + " * " + field.extraLabel
                  : field.required
                  ? field.label + " *"
                  : field.extraLabel
                  ? field.label + " " + field.extraLabel
                  : field.label
              }
              labelformat={field.labelformat}
              error={errors[field.id]}
              type="checkbox"
              required={field.required}
              layout={field.layout}
              icon={field.layout && getIcon(layout.split("_")[1])}
              tooltip={field.tooltip}
              cssStyles={field.css}
            />
          </Box>
          {field.children && // generate elemen for a child question recursively
            field.children.map((item) => {
              return generateFormComponents(item, values, field.id);
            })}
        </React.Fragment>
      );
    } else if (field.type === "radiogroup") {
      return (
        <React.Fragment>
          <RadioFieldGroup
            key={field.id}
            id={field.id}
            width={width}
            direction={field.direction}
            label={
              field.required && field.extraLabel
                ? field.label + " * " + field.extraLabel
                : field.required
                ? field.label + " *"
                : field.extraLabel
                ? field.label + " " + field.extraLabel
                : field.label
            }
            labelformat={field.labelformat}
            error={errors[field.id]}
            required={field.required}
          >
            {field.values.map((val) => {
              return (
                <Field
                  key={val.id}
                  component={RadioField}
                  name={field.id}
                  id={field.id + "-" + val.id}
                  value={val.id}
                  label={val.label}
                  type="radio"
                  cssStyles={field.css}
                />
              );
            })}
          </RadioFieldGroup>
          {field.children && // generate elemen for a child question recursively
            field.children.map((item) => {
              return generateFormComponents(item, values, field.id);
            })}
        </React.Fragment>
      );
    } else if (field.type === "select") {
      return (
        <React.Fragment>
          <FormField
            id={field.id}
            key={field.id}
            component="select"
            width={width}
            name={field.id}
            label={
              field.required && field.extraLabel
                ? field.label + " * " + field.extraLabel
                : field.required
                ? field.label + " *"
                : field.extraLabel
                ? field.label + " " + field.extraLabel
                : field.label
            }
            labelformat={field.labelformat}
            required={field.required}
            cssStyles={field.css}
          >
            <option value={""}>
              {field.defaultValue ||
                translate("form.defaultSelection", null, language)}
            </option>
            {field.values.map((val) => {
              return (
                <option key={val.id} value={val.id}>
                  {val.label}
                </option>
              );
            })}
          </FormField>
          {field.children && // generate elemen for a child question recursively
            field.children.map((item) => {
              return generateFormComponents(item, values, field.id);
            })}
        </React.Fragment>
      );
    } else if (field.type === "checkboxgroup") {
      let layoutType = null;
      if (field.values && field.values[0] && field.values[0].layout) {
        layoutType = field.values[0].layout.split("_")[0];
      }

      let subIds = [];
      return (
        <React.Fragment>
          <CheckboxFieldGroup
            key={field.id}
            id={field.id}
            label={
              field.required && field.extraLabel
                ? field.label + " * " + field.extraLabel
                : field.required
                ? field.label + " *"
                : field.extraLabel
                ? field.label + " " + field.extraLabel
                : field.label
            }
            labelformat={field.labelformat}
            tooltip={field.tooltip}
            layout={layoutType}
            error={errors[field.id]}
            required={field.required}
            checked={field.values ? field.values : []}
          >
            {field.values.map((val) => {
              subIds.push(val.id);
              return (
                <Field
                  component={CheckboxField}
                  key={val.id}
                  multiple={true}
                  setFieldValue={setFieldValue}
                  name={field.id}
                  id={val.id}
                  label={val.label}
                  tooltip={val.tooltip}
                  layout={val.layout}
                  icon={val.layout && getIcon(val.layout.split("_")[1])}
                  type="checkbox"
                />
              );
            })}
          </CheckboxFieldGroup>
          {field.children && // generate elemen for a child question recursively
            field.children.map((item) => {
              return generateFormComponents(item, values, field.id);
            })}
        </React.Fragment>
      );
    }
    return null;
  };

  return (
    <FormikForm>
      <ScrollToFieldError />
      <Flex sx={{ flexWrap: "wrap", mx: -1, "> div": { px: 1 } }}>
        {formFields.map((field) => generateFormComponents(field, values))}
      </Flex>
      <SubmitButton
        formType={type}
        disableCaptcha={disableCaptcha}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        button={button}
        submit={submit}
        setFieldValue={setFieldValue}
        language={language}
        selectedFieldValues={values}
        externalSubmit={externalSubmit}
        carModelId={carModelId}
        errors={errors}
        translate={translate}
      />
    </FormikForm>
  );
};

const initialValueForType = (type) => {
  if (type === "checkbox") return false;
  if (type === "checkboxgroup") return [];
  return "";
};

const generateInitialValues = (formFields, vo) => {
  const values = {};
  for (const field of formFields) {
    // skip info fields
    if (field.type === "info") {
      continue;
    }
    if (field.id) {
      values[field.id] = vo[field.id]
        ? vo[field.id]
        : initialValueForType(field.type);
    }
  }
  return { ...values };
};

const FormikComponent = withFormik({
  mapPropsToValues: ({ vo, formFields }) =>
    generateInitialValues(formFields, vo),
  validationSchema: ({ formFields, translate, type, language }) => {
    const disableCaptcha =
      type === "accessibilityFeedback" || type === "accessibleMaterialOrder";
    return generateValidationSchema(
      formFields,
      translate,
      language,
      disableCaptcha
    );
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    for (let k in values) {
      // remove field valuse not currently rendered before sending the form
      if (!visibleFormFields.includes(k) && k !== "recaptcha") {
        delete values[k];
      }
    }
    onSubmit(values, actions);
  },
  displayName: ({ type }) => type + "InputForm",
})(FormComponent);

const Form = ({ data, language, anchor, customApiSubmitResponce }) => {
  const {
    title,
    formFields,
    description,
    button,
    type,
    forward,
    externalSubmit,
    customApiSubmit,
    disableShowNotification,
    customErrorMsg,
    securedFormAccess,
  } = data || {}; // TODO: fix graphQL query for component
  const { translate } = useContext(LocalizationContext);
  const { showNotification, hideNotification } =
    useContext(NotificationContext);
  const [showSpinner, setShowSpinner] = useState(false);
  const [vo, setVO] = useState({});

  const [isClient, setClient] = useState(false);
  const key = isClient ? "client" : "server";

  const [xAuthHeader, setXAuthHeader] = useState(false);

  if (securedFormAccess) isSecuredForm = true;
  if (xAuthHeader) hashedPswToken = true;

  if (
    securedFormAccess &&
    !hashedPswToken &&
    formFields[0].id !== "securedFormPsw"
  ) {
    formFields.unshift({
      id: "securedFormPsw",
      type: "password",
      label: securedFormAccess.label || "Password",
      required: true,
    });
  }

  useEffect(() => {
    setClient(true);
  }, []);

  useEffect(() => {
    // Recaptcha creates a hidden textarea as a last component, set a dummy label for the given textarea to get rid of the error
    const elements = document.getElementsByTagName("textarea");
    const lastElement = elements[elements.length - 1];

    if (
      lastElement &&
      lastElement.getAttribute("tab-index") === "-1" &&
      lastElement.getAttribute("aria-hidden") === "true" &&
      lastElement.style.getPropertyValue("visibility") === "hidden"
    ) {
      lastElement.setAttribute("aria-label", "Do not use!");
    }
  });

  const onSubmit = useCallback(async (vo, { setSubmitting }) => {
    hideNotification();
    setShowSpinner(true);
    try {
      if (isSecuredForm && !hashedPswToken) {
        const res = await api.sendLoginSubmit({ type, language, ...vo });
        if (res?.headers?.["x-auth-token"] && res?.status === 200) {
          formFields.shift();
          setXAuthHeader(res.headers["x-auth-token"]);
          hashedPswToken = true;
        } 
        setSubmitting(false);
        setShowSpinner(false);
        return;
      } else {
        if (customApiSubmit) {
          customApiSubmitResponce(
            await api[customApiSubmit]({ type, language, ...vo })
          );
          sendFormSubmitEvent(type, language, vo);
        } else {
          await api.sendForm({ type, xAuthHeader, language, ...vo });
          sendFormSubmitEvent(type, language, vo);
        }
        if (forward) {
          navigate(forward);
        }
        if (!disableShowNotification) {
          showNotification(
            translate("form.success", null, language),
            SEVERITY.INFO
          );
        }
      }
      setSubmitting(false);
      setShowSpinner(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
      setShowSpinner(false);
      if (handleFormValidationError(error, showNotification, SEVERITY.ERROR)) {
        return;
      }
      showNotification( isSecuredForm && !hashedPswToken ? securedFormAccess?.errorLoginMsg :
        translate(
          customErrorMsg ? "form." + customErrorMsg : "form.failed",
          null,
          language
        ),
        SEVERITY.ERROR
      );
    }

    setVO({});
    window && window.scrollTo(0, 0);
  });
  const formProps = {
    vo,
    onSubmit,
    translate,
    formFields,
    securedFormAccess,
    button,
    type,
    language,
    showNotification,
    externalSubmit,
    carModelId,
    validateOnChange: false,
  };
  if (!type) return null;
  if (!isClient) return null;
  return (
    <Box id={anchor} sx={{ scrollMarginTop: "104px" }} key={key}>
      {title && <Themed.h2 sx={{ mt: 0, mb: 4 }}>{title}</Themed.h2>}
      {description && <Themed.p>{description}</Themed.p>}
      <FormikComponent key={language} {...formProps} />
      {showSpinner && <Spinner size="medium" position="fixed" />}
    </Box>
  );
};

export default Form;
