/** @jsx jsx */
import { jsx, Box, Label } from "theme-ui";
import { Radio } from "./Radio";

const RadioField = ({ field, id, label, value, form, ...props }) => {
  return (
    <Box sx={{ mr: 2, mb: 1 }}>
      <Label
        variant={
          props.labelformat ? "labels." + props.labelformat : "text.body"
        }
        htmlFor={id}
        sx={{
          maxWidth: "max-content",
        }}
      >
        <Radio
          name={field.name}
          id={id}
          value={value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...props}
        />
        {label}
      </Label>
    </Box>
  );
};

export default RadioField;
