const carModelId = [{"carMake":"Alfa Romeo","modelId":21},
{"carMake":"Aston Martin","modelId":42},
{"carMake":"Audi","modelId":20},
{"carMake":"Bentley","modelId":41},
{"carMake":"BMW","modelId":6},
{"carMake":"Cadillac","modelId":39},
{"carMake":"Carrus","modelId":55},
{"carMake":"Case IH","modelId":76},
{"carMake":"Chevrolet","modelId":35},
{"carMake":"Chevrolet US","modelId":28},
{"carMake":"Chrysler","modelId":27},
{"carMake":"Citroen","modelId":7},
{"carMake":"Claas","modelId":79},
{"carMake":"Dacia","modelId":66},
{"carMake":"DAF","modelId":57},
{"carMake":"Daihatsu","modelId":19},
{"carMake":"Derbi","modelId":86},
{"carMake":"Deutz-Fahr","modelId":68},
{"carMake":"Dodge","modelId":32},
{"carMake":"Ducati","modelId":82},
{"carMake":"Fendt","modelId":78},
{"carMake":"Ferrari","modelId":43},
{"carMake":"Fiat","modelId":12},
{"carMake":"Ford","modelId":5},
{"carMake":"Ford US","modelId":67},
{"carMake":"Galloper","modelId":49},
{"carMake":"Harley Davidson","modelId":83},
{"carMake":"Honda","modelId":14},
{"carMake":"Hyundai","modelId":17},
{"carMake":"Isuzu","modelId":22},
{"carMake":"Iveco","modelId":26},
{"carMake":"Jaguar","modelId":31},
{"carMake":"Jeep","modelId":29},
{"carMake":"John Deere","modelId":73},
{"carMake":"Kawasaki","modelId":80},
{"carMake":"Kia","modelId":38},
{"carMake":"KTM","modelId":84},
{"carMake":"Lada","modelId":25},
{"carMake":"Lamborghini","modelId":69},
{"carMake":"Lancia","modelId":48},
{"carMake":"Land Rover","modelId":61},
{"carMake":"Lexus","modelId":40},
{"carMake":"Lotus","modelId":44},
{"carMake":"MAN","modelId":56},
{"carMake":"Maserati","modelId":45},
{"carMake":"Massey Ferguson","modelId":74},
{"carMake":"Mazda","modelId":9},
{"carMake":"Mercedes-Benz","modelId":8},
{"carMake":"MG","modelId":64},
{"carMake":"Mini","modelId":63},
{"carMake":"Mitsubishi","modelId":15},
{"carMake":"Morgan","modelId":46},
{"carMake":"Neoplan","modelId":53},
{"carMake":"New Holland","modelId":75},
{"carMake":"Nissan","modelId":16},
{"carMake":"Opel","modelId":3},
{"carMake":"Peugeot","modelId":11},
{"carMake":"Piaggio","modelId":87},
{"carMake":"Pontiac","modelId":37},
{"carMake":"Porsche","modelId":33},
{"carMake":"Qoros","modelId":90},
{"carMake":"Renault","modelId":10},
{"carMake":"Rolls Royce","modelId":47},
{"carMake":"Rover","modelId":30},
{"carMake":"Saab","modelId":4},
{"carMake":"Scania","modelId":52},
{"carMake":"Seat","modelId":24},
{"carMake":"Setra","modelId":54},
{"carMake":"Sisu","modelId":50},
{"carMake":"Skoda","modelId":34},
{"carMake":"Smart","modelId":62},
{"carMake":"SsangYong","modelId":36},
{"carMake":"Subaru","modelId":23},
{"carMake":"Suzuki","modelId":18},
{"carMake":"Tata","modelId":65},
{"carMake":"Tesla","modelId":58},
{"carMake":"Toyota","modelId":13},
{"carMake":"Triumph","modelId":85},
{"carMake":"Valtra","modelId":77},
{"carMake":"Volvo","modelId":1},
{"carMake":"VW","modelId":2},
{"carMake":"Yamaha","modelId":81},
{"carMake":"Yleisauto","modelId":99}];

export default carModelId;