/** @jsx jsx */
import { jsx, Box, Flex, Label } from "theme-ui";

const RadioFieldGroup = ({ children, ...props }) => {
  return (
    <Box
      as="fieldset"
      sx={{
        mb: 3,
        py: 0,
        px: 1,
        width: props.width ? props.width : "100%",
        border: "none",
        display: props.visible === false ? "none" : "inline-block",
      }}
    >
      {props.label && (
        <Label
          as="legend"
          variant={props.labelformat ? "labels." + props.labelformat : ""}
        >
          {props.label}
        </Label>
      )}
      <Flex
        sx={{
          flexWrap: "wrap",
          flexDirection: props.direction ? props.direction : "column",
          py: props.direction === "row" ? [0, 2] : 0,
        }}
      >
        {children}
      </Flex>
      {props.error && (
        <Box
          sx={{ color: "error", fontSize: 0, minHeight: 20, lineHeight: 1 }}
          role="alert"
        >
          {props.error}
        </Box>
      )}
    </Box>
  );
};

export default RadioFieldGroup;
