/** @jsx jsx */
import { jsx, Themed, Box, Flex, Select, Button } from "theme-ui";

const TableData = ({ row }) => {
  return (
    <Flex
      scope="row"
      role="row"
      sx={{
        flexWrap: "wrap",
        width: "100%",
        py: 12,

        ":nth-child(even)": {
          bg: "lightGreen",
        },
      }}
    >
      {row.map((data, index) => (
        <Box
          key={index}
          role="cell"
          sx={{
            textAlign: "left",
            flex: "none",
            width: [(1 / row.length) * 100 + "%"],
            mt: [2, 0],
            wordWrap: "break-word",
          }}
        >
          {data}
        </Box>
      ))}
    </Flex>
  );
};

const Table = ({ data, anchor, onFilterSelectChange, onFilterButtonClick }) => {
  const { headers, title, rows, footer, filterOptions, filterButton } = data;
  if (!rows) return null;

  return (
    <Box
      id={anchor}
      sx={{
        scrollMarginTop: "104px",
        breakInside: "avoid",
        "@media print": {
          marginLeft: 15,
          marginRight: 15,
        },
      }}
    >
      {title && <Themed.h2>{title}</Themed.h2>}
      <Flex bg="primary">
        <Flex
          bg="muted"
          style={{
            width: "100%",
            paddingRight: "0",
            justifyContent: "flex-end",
          }}
        >
          {filterOptions && (
            <Select onChange={(evt) => onFilterSelectChange(evt)}>
              {filterOptions.map((obj, index) => (
                <option key={index} value={obj.val} selected={obj.selected}>
                  {obj.txt}
                </option>
              ))}
            </Select>
          )}
          {filterButton && (
            <Button
              variant="primary"
              onClick={(evt) => onFilterButtonClick(evt)}
            >
              {filterButton.txt}
            </Button>
          )}
        </Flex>
      </Flex>

      <Box sx={{ mx: [-2, 0] }} role="table">
        {headers && (
          <Flex
            sx={{
              flexWrap: ["wrap", "nowrap"],
              alignItems: "flex-end",
              width: "100%",
              position: "sticky",
              top: [64, null, 103],
              bg: "white",
              zIndex: 10,
              "@media print": {
                position: "static",
              },
            }}
            role="row"
          >
            {headers?.map((header) =>
              header ? (
                <Box
                  scope="col"
                  role="columnheader"
                  key={header}
                  sx={{
                    flex: [1, "none"],
                    width: [(1 / headers.length) * 100 + "%"],
                    wordWrap: "break-word",
                    textAlign: "left",
                    py: 1,
                    borderBottomStyle: "solid",
                    borderBottomColor: "grey",
                    borderBottomWidth: "2px",
                    fontSize: 1,
                  }}
                >
                  {header}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: ["none", "block"],
                    flex: 1,
                    width: [0, "auto"],
                    py: 12,
                    px: 2,
                    borderBottomStyle: "solid",
                    borderBottomColor: "grey",
                    borderBottomWidth: "2px",
                  }}
                ></Box>
              )
            )}
          </Flex>
        )}
        {rows.map((row, index) => (
          <TableData key={index} row={row} />
        ))}
      </Box>
      {footer && <Themed.i>{footer}</Themed.i>}
    </Box>
  );
};

export default Table;
