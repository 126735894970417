/** @jsx jsx */
import { jsx, Box, Label } from "theme-ui";
import { useContext, useState, useEffect, useRef } from "react";
import { ErrorMessage, Field as FormikField, FormikContext } from "formik";
import { CaretIcon, InfoIcon } from "./Icon";
import Tooltip from "./Tooltip";

const Field = ({ focus, ...props }) => {
  return (
    <Box
      sx={{
        position: "relative",
        mb: 1,
        boxSizing: "border-box",
        select: {
          appearance: "none",
          fontFamily: "body",
          cursor: "pointer",
        },
      }}
    >
      <FormikField
        {...props}
        sx={{
          width: props.component === "select" ? "101.1%": "100%",
          fontFamily: "body",
          fontSize: 2,
          py: 2,
          pl: 1,
          pr: 0,
          bg: "lightGreen",
          border: "1px solid",
          borderColor: "primary",
          borderRadius: 0,
          lineHeight: 1.2,
          "::-webkit-input-placeholder": {
            color: "darkGrey",
            opacity: 1,
          },
          "::-moz-placeholder": {
            color: "darkGrey",
            opacity: 1,
          },
          ":-ms-input-placeholder": {
            color: "darkGrey",
            opacity: 1,
          },
          "::placeholder": {
            color: "darkGrey",
            opacity: 1,
          },
          ":focus": {
            outline: "none",
            bg: "white",
          },
        }}
      />

      {props.component === "select" && (
        <CaretIcon
          sx={{
            position: "absolute",
            right: 2,
            top: 3,
            transform: "scale(2)",
            transformOrigin: "top right",
            pointerEvents: "none"
          }}
        />
      )}
    </Box>
  );
};

const FormField = ({ hidden, helperText, zIndex, required, visible, ...props }) => {
  const formikContext = useContext(FormikContext);
  const hasValue = !!formikContext.values[props.name];
  const [focus, setFocus] = useState(hasValue ? true : false);
  const { isValid, isSubmitting, errors } = formikContext;
  const name = props.name;
  const ref = useRef(null);
  useEffect(() => {
    if (isValid || !isSubmitting) return;
    const errorKey = Object.keys(errors)[0];
    if (!isValid && errorKey === name) {
      ref.current && ref.current.focus();
    }
  }, [isSubmitting, isValid, errors, name]);
  const tooltipShouldMoveInsideTextArea =
    props.type === "text" && props.tooltip;

  return hidden || visible === false ? null : (
    <Box
      sx={{
        position: "relative",
        mb: props.cssStyles?.mb ? props.cssStyles.mb : 1,
        width: props.width ? props.width : "100%",
      }}
    >
      <Label htmlFor={props.id} variant={ props.labelformat ? 'labels.'+ props.labelformat : ""}>{props.label}</Label>
      <Box
        sx={{
          position: "relative",
          display: tooltipShouldMoveInsideTextArea ? "inline-block" : null,
        }}
      >
        <Field
          {...props}
          innerRef={ref}
          focus={focus}
          aria-required={required}
        />
        {props.tooltip && (
          <Box sx={{ position: "absolute", top: "0.8em", right: 2 }}>
            <Tooltip placement="top" tooltip={props.tooltip}>
              <InfoIcon sx={{ color: "primary" }} />
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box sx={{ fontSize: 0, minHeight: 20, lineHeight: 1 }}>
        {helperText && <Box sx={{ mb: 1, color: "gray" }}>{helperText}</Box>}
        <ErrorMessage name={props.name}>
          {(msg) => (
            <Box
              sx={{
                color: "error",
              }}
              role="alert"
            >
              {msg}
            </Box>
          )}
        </ErrorMessage>
      </Box>
    </Box>
  );
};

export default FormField;
